<template>
  <div class="upload-dropzone-container">
    <Vue2Dropzone
      id="upload-0"
      class="max-w-1/2 max-h-1/2 border-0 cursor-pointer"
      :class="{ 'avatar-upload': isAvatar(), 'photo-upload': isPhoto() }"
      ref="upload"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      v-on:vdropzone-success="uploadSuccess"
      v-on:vdropzone-error="uploadError"
      v-on:vdropzone-removed-file="fileRemoved"
      v-on:vdropzone-file-added="(file) => fileAdded(file)"
    >
      <div class="w-full h-full flex justify-center">
        <span v-if="isPhoto()">
          <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="40" cy="40" r="40" fill="#FAFAFA" />
            <path
              d="M33.1 37H35.485V44.5C35.485 45.325 36.16 46 36.985 46H42.985C43.81 46 44.485 45.325 44.485 44.5V37H46.87C48.205 37 48.88 35.38 47.935 34.435L41.05 27.55C40.465 26.965 39.52 26.965 38.935 27.55L32.05 34.435C31.105 35.38 31.765 37 33.1 37ZM29.5 50.5C29.5 51.325 30.175 52 31 52H49C49.825 52 50.5 51.325 50.5 50.5C50.5 49.675 49.825 49 49 49H31C30.175 49 29.5 49.675 29.5 50.5Z"
              fill="#9E9E9E"
            />
          </svg>
        </span>
        <span v-if="isAvatar()">
          <svg
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="56" cy="56" r="56" fill="#E0E0E0" />
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="112"
              height="112"
            >
              <circle cx="56" cy="56" r="56" fill="#E0F1F2" />
            </mask>
            <g mask="url(#mask0)">
              <path
                d="M56.8749 84.3594C27.4443 84.3594 3.5 108.304 3.5 137.734C3.5 139.015 4.53972 140.055 5.82072 140.055H107.929C109.21 140.055 110.25 139.015 110.25 137.734C110.25 108.304 86.3055 84.3594 56.8749 84.3594Z"
                fill="#FAFAFA"
              />
              <path
                d="M56.8745 79.0542C70.9727 79.0542 82.4016 67.6253 82.4016 53.5271C82.4016 39.4289 70.9727 28 56.8745 28C42.7763 28 31.3474 39.4289 31.3474 53.5271C31.3474 67.6253 42.7763 79.0542 56.8745 79.0542Z"
                fill="#FAFAFA"
              />
            </g>
          </svg>
        </span>
      </div>
    </Vue2Dropzone>
    <div class="font-caption text-caption px-4 py-2 w-full" :class="warningClass">
      {{warning}}
    </div>
  </div>
</template>

<script>
import Vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    Vue2Dropzone
  },
  props: {
    type: {
      validator: value => {
        return ['photo', 'avatar'].indexOf(value) !== -1
      },
      default: () => {
        return 'photo'
      }
    },
    warning: {
      type: String,
      default: ''
    },
    warningClass: {
      type: String,
      default: 'text-gray-500'
    }
  },
  data () {
    return {
      dropzoneOptions: {
        url: '/',
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
        dictDefaultMessage: `
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="40" fill="#FAFAFA"/>
            <path d="M33.1 37H35.485V44.5C35.485 45.325 36.16 46 36.985 46H42.985C43.81 46 44.485 45.325 44.485 44.5V37H46.87C48.205 37 48.88 35.38 47.935 34.435L41.05 27.55C40.465 26.965 39.52 26.965 38.935 27.55L32.05 34.435C31.105 35.38 31.765 37 33.1 37ZM29.5 50.5C29.5 51.325 30.175 52 31 52H49C49.825 52 50.5 51.325 50.5 50.5C50.5 49.675 49.825 49 49 49H31C30.175 49 29.5 49.675 29.5 50.5Z" fill="#9E9E9E"/>
          </svg>
        `
      },
      fileName: ''
    }
  },
  methods: {
    isPhoto () {
      return this.type === 'photo'
    },
    isAvatar () {
      return this.type === 'avatar'
    },
    uploadSuccess (file, response) {
    // console.log('File Successfully Uploaded with file name: ' + response.file)
      this.fileName = response.file
    },
    uploadError (file, message) {
    // console.log('An Error Occurred')
    },
    fileRemoved () {
      this.$emit('file-removed')
    },
    fileAdded (file) {
      this.$emit('file-added', file)
    }
  }
}
</script>

<style scoped>
.upload-dropzone-container >>> .avatar-upload.vue-dropzone {
  @apply border-0 py-0;
}
.upload-dropzone-container >>> .avatar-upload.vue-dropzone .dz-message {
  @apply m-0;
}
.upload-dropzone-container >>> .photo-upload.vue-dropzone {
  @apply border-2 border-gray-100 bg-transparent;
}
.upload-dropzone-container >>> .vue-dropzone {
  @apply border-0 bg-transparent;
}
.upload-dropzone-container >>> .vue-dropzone:hover {
  @apply bg-none;
}</style>
