<template>
  <div v-show="show" class="mt-16" :class="{ absolute: !relative, 'left-1/2': !normal }">
    <div class="rounded-md flex items-center jusitfy-between px-5 py-4 mb-2 border  bg-gradient-to-b relative" :class="{ '-left-1/2': !normal, 'border-secondary from-orange-accent-translucent bg-white text-orange-accent': (type=='warning'), 'border-green from-green-translucent bg-white text-green': (type=='success')  }">
        <div class="w-full flex items-center px-2.5">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="type=='warning'">
              <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM10 7C9.45 7 9 6.55 9 6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6C11 6.55 10.55 7 10 7Z" fill="#F78522"/>
            </svg>
            <p class="font-subtitle-2 text-subtitle-1 pl-5">
              <slot></slot>
            </p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    relative: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    normal: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    type: {
      type: String,
      default: 'warning'
    }
  }
}
</script>
